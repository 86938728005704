.repurchase-widget {
  position: relative;

  &-header {
    display: flex;
    padding: $andes-spacing-20;
  }

  &__title {
    h2 {
      line-height: $andes-spacing-20;
      margin: 0;
      margin-right: $andes-spacing-12;
      font-weight: $font-weight-semibold;
      font-size: $font-size-20;
      color: $andes-gray-900;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      align-items: baseline;
    }
  }

  &__subtitle {
    cursor: pointer;

    &-link {
      color: $andes-blue-500;
      font-size: $font-size-14;
      font-weight: 600;
      margin: 0;
      text-decoration: none;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
      }
    }
  }

  &__item-title {
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
    font-size: $font-size-16;
    font-family: $font-family-primary;
    color: $andes-text-color-primary;
    margin-bottom: $andes-spacing-8;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 0;
  }

  &__item-description {
    align-items: flex-start;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;

    .andes-money-amount-combo__main-container {
      flex-wrap: wrap;

      .andes-money-amount__cents {
        font-size: $font-size-12;
      }
    }
    .andes-money-amount--previous .andes-money-amount__cents {
      font-size: 8px;
    }
  }

  &__item-description--with-actions {
    margin: $andes-spacing-12 $andes-spacing-16 $andes-spacing-8;
  }

  &__container-shipping-free {
    color: $andes-green-500;
    display: flex;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 17px;
  }

  &__item-attributes {
    font-size: $font-size-12;
    color: $andes-gray-550;
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
  }

  &__container-tag {
    margin: $andes-spacing-4 0;
    max-height: $andes-spacing-24;
  }

  &__container-tag svg{
    margin: auto $andes-spacing-4 auto auto;
  }

  &__container-tag span {
    border-radius: $andes-spacing-4;
    color: $andes-white;
    display: flex;
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    height: fit-content;
    line-height: $line-height-s;
    padding: 3px $andes-spacing-4 2px;
    width: fit-content;
  }

  &__image {
    &--LANDSCAPE {
      width: 100%;
      margin: 0;

      img {
        object-position: center;
      }
    }
  }

  &__subtext {
    font-weight: $font-weight-regular;
    color: $andes-text-color-secondary;
    margin-left: 3px;
  }

  .ui-homes-icon--full {
    fill: $andes-green-500;
    vertical-align: middle;
    width: 38px;
    height: 15px;
  }

  &__price {
    margin: 0;
    margin-bottom: 2px;
  }

  &__items-images {
    height: 100%;
    margin: $andes-spacing-4;

    img {
      height: 100%;
      object-fit: contain;
    }

    &__label {
      position: absolute;
      top: 35%;
      right: 40%;
      color: $andes-blue-500;
      font-weight: $font-weight-semibold;
      font-size: $font-size-18;
    }
  }

  &__one-item-container {
    padding: $andes-spacing-20;
    .repurchase-widget__image {
      height: auto;
      width: 100%;
      object-fit: cover;
    }
  }

  &__items-images--2 {
    display: flex;
    justify-content: space-between;

    & > :first-child {
      margin-right: $andes-spacing-8;
    }
  }

  &__3-items-container {
    display: flex;
    align-items: center;
    height: 100%;

    &-left {
      margin-right: $andes-spacing-4;
      height: calc(100% - $andes-spacing-8);
      width: 50%;

      img {
        object-fit: scale-down;
      }
    }

    &-right {
      margin-left: $andes-spacing-4;
      width: 50%;
      height: calc(100% - $andes-spacing-8);

      & > :first-child {
        margin-bottom: $andes-spacing-8;
      }

      &-item {
        height: calc(50% - $andes-spacing-4);
      }

      img {
        object-fit: scale-down;
      }
    }
  }

  &__items-images--4 {
    display: flex;
    flex-wrap: wrap;

    .repurchase-widget__items-images-container {
      flex: 1 1 calc(50% - $andes-spacing-4);
    }

    .repurchase-widget__items-images-container:first-child {
      margin-bottom: $andes-spacing-4;
      margin-right: $andes-spacing-4;
    }

    .repurchase-widget__items-images-container:nth-child(2) {
      margin-bottom: $andes-spacing-4;
      margin-left: $andes-spacing-4;
    }

    .repurchase-widget__items-images-container:nth-child(3) {
      margin-top: $andes-spacing-4;
      margin-right: $andes-spacing-4;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  &__items-images--last-image {
    width: calc(50% - $andes-spacing-4);
    position: relative;
    margin-top: $andes-spacing-4;
    margin-left: $andes-spacing-4;

    img {
      width: 100%;
      object-position: center;
    }
  }

  &__items-images__label {
    font-size: 36px;
  }

  &__items-images--additional_items img {
    opacity: 0.3;
  }
}
