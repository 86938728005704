@import '~@recommendations-fe/carousel-snapped/src/styles/recommendations-fe-carousel-snapped-homes.desktop';

// Snapped
.ui-recommendations-snapped-section {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  @import './seeds';
}

.ui-recommendations-card.ui-recommendations-card--vertical.highlight-seed {
  .ui-recommendations-card__bookmark-button {
    top: $andes-spacing-32;
    right: $andes-spacing-32;
  }

  .ui-recommendations-card__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dadada;
    height: 220px;
    padding: $andes-spacing-20;

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 -1px 30px 0 rgb(0 0 0 / 20%), 0 2px 3px 0 rgb(0 0 0 / 10%);
      border-radius: $border-radius-4;
    }
  }

  border: none;
}

.ui-recommendations-snapped-section {
  .ui-recommendations-carousel-wrapper-ref {
    @media (width < 1180px) {
      padding: 0 24px;
    }

    .new-carousel {
      padding: $andes-spacing-20;
      box-shadow: $shadow-flat;
      @media (width < 1180px) {
        max-width: 100%;
      }

      &:not(.ui-recommendations-carousel-dual .new-carousel) {
        margin: 42px auto;
      }

      .poly-card.poly-card--grid {
        --poly-general-title-hover-color: #{$andes-blue-500};
        --poly-general-image-divider: unset;
        --poly-padding-content: 0;
      }
    }

    .ui-recommendations-carousel-snapped:not(.new-carousel) {
      .ui-recommendations-title {
        padding-right: 16px;
      }
    }
  }
}

.highlight-seed {
  [class*='poly-column__portada'] {
    padding: $andes-spacing-20;
    background-color: #dadada;
    border: none;

    img {
      box-shadow: 0 -1px 30px 0 rgb(0 0 0 / 20%), 0 2px 3px 0 rgb(0 0 0 / 10%);
      border-radius: $border-radius-4;
    }
  }
}
