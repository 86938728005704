@import './components/sliders/desktop';
.navigation {
  .view-history {
    font-weight: $font-weight-semibold;
  }
  .andes-carousel-snapped__container.andes-carousel-snapped__container--content {
    margin: 0 2px;
    width: calc(100% - 4px);
  }

  .andes-carousel-snapped__header {
    position: absolute;
    top: -35px;
  }

  .andes-carousel-snapped__control {
    top: 40%;
    &--previous {
      left: -55px;
    }

    &--next {
      right: -55px;
    }
  }

  .row.container {
    margin-bottom: 42px;
    background-color: white;
    max-width: 1140px;
    padding: $andes-spacing-20;
    border-radius: $border-radius-6;
    box-shadow: $shadow-flat;
  }
}
