.navigation-slide {
  position: relative;

  .card-item__wrapper {
    max-width: 174px;
    &:hover {
      .card-item__title {
        color: $andes-blue-500;
      }
    }
  }
  a.card-item {
    text-decoration: none;
  }

  img {
    height: 170px;
    width: 100%;
  }

  .card-item__title {
    color: $andes-text-color-primary;
    font-family: $font-family-primary;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: 18px;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    text-decoration: none;
    overflow: hidden;
    margin-top: 5px;
  }

  .card-item__title:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
