@import "../../../commons/card/card-grid/card-grid.desktop";

$filler-color: #eae6e6;
$category-with: 270px;
$category-height: 100px;

.categories-new-design {
  .section-header {
    background: $andes-white;
    width: calc(100% - 45px);
    margin: 0;
    border-top-left-radius: 6px;
    padding-top: 20px;
    padding-left: 12px;
  }
  .wrapper {
    height: 100%;
    width: 100%;
    border-radius: 6px;
    background:$andes-white;
    padding-left: 8px;
    box-sizing: border-box;
    box-shadow: $shadow-flat;

    .andes-carousel-snapped__control {
      top: 47.5%;
    }
    .andes-carousel-snapped__control--next {
      right: -52px;
    }
    .andes-carousel-snapped__control--previous {
      left: -52px;
    }
    .section-header a {
      font-weight: 600;
      z-index: 999;
    }
    .andes-carousel-snapped__header {
      position: relative;
      top: -10px;
      right: -20px;
    }
    .andes-carousel-snapped__container--content {
      width: calc(100% - 32px);
      margin-left: 12px;
    }
    .andes-carousel-snapped__pagination--position-top {
      margin-right: 22px;
    }
    .andes-carousel-snapped__slide img {
      object-fit: contain;
      object-position: center;
    }
    .andes-carousel-snapped__slide {
      cursor: auto;
    }
    .category-column {
      .card-grid-item {
        width: 210px;

        @media (width >= 1110px) {
          width: 250px;
        }
  
        @media (width >= 1180px) {
          width: 270px;
        }
      } 
    }
  }
  .filler {
    max-width: $category-with;
    height: $category-height;
    background-color: $filler-color;
    margin-bottom: 20px;
    border-radius: 6px;
    &:focus-visible {
      box-shadow: none;
    }
  }
  .andes-card > :first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card-grid-item:focus-visible {
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #2968C8, 0 0 0 5px rgba(65, 137, 230, 0.3);
    left: 3px;
    top: 3px;
  }
}
