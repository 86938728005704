@import './widget';

.repurchase-widget {

  &-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 1px;
    border-radius: $border-radius-6;
    background: $andes-white;
  }

  &-header {
    align-items: baseline;
    overflow: hidden;
  }

  &__title {
    font-size: $font-size-20;
    min-width: 0;
    line-height: 19px;
  }

  &__subtitle {
    flex-shrink: 0;
    white-space: nowrap;
  }

  &__one-item-container {
    padding: $andes-spacing-20;

    .repurchase-widget__image {
      height: auto;
      width: 65%;
      margin: 0 auto;
      object-fit: cover;
    }

    .repurchase-widget__item-description {
      margin-top: $andes-spacing-8;
    }
  }

  &__3-items-container {
    margin: $andes-spacing-4;
  }

  .ui-homes-icon--full {
    fill: $andes-green-500;
    vertical-align: middle;
    width: 41px;
    height: 13px;
  }

  &__items-images__label {
    font-size: 40px;
  }

  &:hover {
    .repurchase-widget__one-item-container {
      .repurchase-widget__item-title {
        color: $andes-blue-500;
      }
    }
  }
}