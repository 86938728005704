.AD_ADN_area {
  max-width: 1180px;
  margin: 0 auto;

  @media (width < 1180px) {
    margin: 0 24px;
  }

  div[data-placement='adn'] {
    padding: 40px 0;
  }
}
