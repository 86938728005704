.ui-recommendations-carousel-dual {
  max-width: 1180px;
  margin: 42px 24px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1180px) {
    margin: 42px auto;
  }

  .ui-recommendations-carousel-wrapper-ref {
    padding: 0;
  }

  &__first-card {
    width: calc(30% - 16px);
    margin-right: 16px;

    .andes-carousel-snapped__wrapper {
      height: calc(var(--carousel-dual-height));
    }

    .new-carousel {
      width: 100%;
      margin: 0;

      .andes-carousel-snapped__slide img {
        width: auto;
      }

      .poly-card.poly-card--grid {
        --max-card-height: 100%;
        --poly-general-title-line-height: 20px;
        --poly-general-title-size: #{$font-size-16};
        --poly-max-width: 100%;
        --poly-padding-content: 0;

        .poly-card__content {
          gap: $andes-spacing-8;

          .poly-component__shipping,
          .poly-component__price,
          .poly-price__installments {
            margin: 0;
          }
        }
      }
    }
  }

  &__carousel {
    width: 70%;

    .new-carousel {
      width: 100%;
      margin: 0;
    }
  }

  .andes-carousel-snapped__container {
    height: var(--carousel-dual-height);
  }
}
