@import '~@andes/button/index';
@import '~@andes/modal/index';

.interstitial--old {
  height: 100%;

  iframe {
    height: 100%;
    user-select: none;
    border: 0;
  }

  .andes-modal {
    &__content {
      padding: 0;
      width: 100%;
      height: 100%;
    }

    &__scroll {
      height: 100%;
      overflow: hidden;
      border-radius: 6px;
    }
  }

  & > div:first-of-type:focus-visible {
    box-shadow: none;
  }
}

.interstitial {
  text-align: center;

  &-close-container {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    svg {
      width: 28px;
      height: 28px;
    }
  }

  &-image-container {
    img {
      width: fill-available;
      height: calc(78vh - 3em);
      max-height: 550px;

      @media (height <= 650px) {
        height: auto;
        max-height: calc(510px - 3em);
      }
    }
  }

  &-terms-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 $andes-spacing-20;
    margin-bottom: $andes-spacing-32;
    font-size: $font-size-14;
    span { 
      width: 100%;
      max-width: 480px; 
    }
    a {
      color: $andes-blue-base;
      text-decoration: none;
    }
  }

  &-buttons-container {
    &--has_disclaimer {
      margin-top: 0;
      width: 100%;
      padding: 0 $andes-spacing-20;
      margin-bottom: 6px;
      .interstitial-buttons {
        width: 100%;
        button {
          width: 100%;
          max-width: 380px;
        }
      }
    }
  }

  &-buttons-container, &-terms-container {
    box-sizing: border-box;
    background-color: $andes-white;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    .interstitial-buttons {
      padding: 1rem;

      button.andes-button {
        min-width: 200px;
        white-space: nowrap;
        box-shadow: none;

        &:first-of-type {
          margin-right: $andes-spacing-4;
        }

        &:not(:first-of-type) {
          margin-left: $andes-spacing-4;
        }

        &--transparent {
          &:active,
          &:focus {
            background-color: $andes-white;
          }
        }
      }
    }
  }
}

.andes-modal--large .andes-modal__content {
  padding: unset;
}
