@import "./items-slide-desktop";

.collections {
  * {
    box-sizing: border-box;
  }

  .andes-carousel-snapped__container.andes-carousel-snapped__container--content {
    margin: 0;
    width: 100%;
  }

  .andes-carousel-snapped--scroll-hidden {
    overflow-y: hidden;
  }

  .ui-card { 
    border-radius: 6px;
    box-shadow: $shadow-flat;
  }

  .andes-carousel-snapped__container--content 
  .andes-carousel-snapped__control--previous {
    left: -32px;
  }

  .andes-carousel-snapped__container--content 
  .andes-carousel-snapped__control--next {
    right: -32px;
  }
}
