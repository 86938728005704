.dynamic-access-card {
  justify-content: flex-start;

  &-ecosistemic {
    flex-direction: column;
    display: flex;
    padding-bottom: 0;
    height: 100%;

    &-header {
      display: flex;
      max-width: 183px;
    }

    &__title {
      line-height: 20px;
      width: 100%;
      padding: $andes-spacing-16 $andes-spacing-12 $andes-spacing-16
        $andes-spacing-16;
      margin: 0;
      text-decoration: none;
      font-weight: $font-weight-semibold;
      font-size: $font-size-16;
      color: $andes-gray-900;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      display: flex;
      height: 105px;
      justify-content: center;
      align-items: center;
      margin: auto;
    }

    &-description {
      padding: $andes-spacing-12 $andes-spacing-16;
      height: 52px;
      color: $andes-text-color-primary;
      text-align: center;
      font-family: $font-family-primary;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      line-height: 18px;

      .dynamic-access-card-ecosistemic__description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &-footer {
      border-radius: $border-radius-4;
      background: $andes-blue-150;
      margin: 17px;
      max-height: 24px;
      display: flex;
      justify-content: center;
    }

    &__action {
      color: $andes-blue-500;
      text-align: center;
      padding: 6px $andes-spacing-8 6px $andes-spacing-8;
      font-family: $font-family-primary;
      font-size: $font-size-12;
      font-weight: $font-weight-semibold;
      line-height: 12px;
      text-decoration-line: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 25px;
    }

    &__action.andes-button {
      padding: 0 0;
    }

    &__action::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
