// Global
$andes-theme: 'mercadolibre';

// Shared
@import '../../../styles/_common';

// Andes
@import '~@andes/dropdown/index';
@import '~@andes/floating-menu/base';
@import '~@andes/list/index';
@import '~@andes/common/index';
@import '~@andes/common/secondary-palette';
@import '~@andes/card/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/loud';
@import '~@andes/button/lib/styles/modifier/quiet';
@import '~@andes/button/lib/styles/size/medium';
@import '~@andes/badge/base';
@import '~@andes/badge/lib/styles/modifier/pill';
@import '~@andes/badge/lib/styles/size/large';
@import '~@andes/badge/lib/styles/size/small';
@import '~@andes/badge/lib/styles/color/green';
@import '~@andes/spinner/base';
@import '~@andes/spinner/lib/styles/modifier/block';
@import '~@andes/spinner/lib/styles/modifier/fullscreen';
@import '~@andes/spinner/lib/styles/size/large';
@import '~@andes/spinner/lib/styles/size/medium';
@import '~@andes/money-amount/base';
@import '~@andes/carousel-free/index';
@import '~@andes/carousel-snapped/index';

// Components
@import '../../commons/advertisement/advertisement';
@import './loyalty-partner-subscriptions/loyalty-partner-subscriptions';

main {
  background-color: $home-background;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
}

.home {
  /* flexbox grid */
  .row {
    position: relative;
  }

  .ui-card {
    background-color: $andes-white;
    border-radius: $border-radius-4;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    overflow: hidden;
  }


  .poly-card {
    &__content {
      [class*='poly-component__'] {
        // Ultimo componente dentro de content
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal {
  iframe {
    width: 100%;
    border: 0;
    -webkit-overflow-scrolling: touch;
  }
}
