@mixin box-hover-shadow() {
  transition: box-shadow $transition-duration $transition-timing-function;
  box-shadow: 0 7px 16px 0 rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 10%);
}

@mixin prev-button-resize() {
  @media screen and (width <= 1180px) {
    .prev-button {
      left: 1px;
    }
  }
}
