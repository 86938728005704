@import '~@ads-placements-frontend/discovery/styles/index.desktop';

.discovery__body {
  padding-left: 0;

  > a.advertising {
    margin-bottom: 0;
  }
  a:focus-visible {
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #2968C8, 0 0 0 5px rgba(65, 137, 230, 0.3);
    outline: none;
  }
}
