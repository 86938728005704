@import '~@andes/common/index';

a {
  text-decoration: none;
  color: inherit;
}

.dynamic-access-card-poster-item {
  position: relative;

  &__title {
    line-height: 20px;
    padding: $andes-spacing-16;
    margin: 0;
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    color: $andes-gray-900;
    max-width: 183px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-content__title-name {
    font-size: $font-size-14;
    line-height: 18px;
    font-weight: $font-weight-regular;
  }

  .description-content__link::before,
  .description-content__link::after {
      transform: none;
      opacity: 0;
  }

  .description-content__title-name::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__item-title {
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    font-family: $font-family-primary;
    color: $andes-text-color-primary;
    margin-bottom: $andes-spacing-8;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__info {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    padding: 16px;
    display: grid;
    align-items: flex-end;
    line-height: 18px;
    font-size: $font-size-14;
  }

  &__description {
    font-family: $font-family-primary;
    font-weight: $font-weight-regular;
    font-size: $font-size-12;
    line-height: 18px;
    color: $andes-gray-550-solid;
  }

  &__description-content_v2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: $font-weight-semibold;
    margin-bottom: $andes-spacing-8;
  }

  &__image {
    height: 100%;
    width: 100%;
    position: relative;
    aspect-ratio: 16/9;
    img {
      border-radius: $border-radius-6;
    }
    &::after {
      content: '';
      bottom: 0;
      height: 90px;
      right: 0;
      left: 0;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.8) 50%
      );
      border-radius: $border-radius-6;
    }
  }

  &__progress {
    position: absolute;
    height: 4px;
    bottom: 0;
    background: $andes-gray-550-solid;
    border-radius: 0;
    .fill {
      border-radius: 0;
    }
  }

  &__remaining-info {
    position: absolute;
    bottom: 0;
    display: flex;
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    line-height: 15px;
    height: 23px;
    color: $andes-white;
    width: 100%;

    .remaining-icon,
    .remaining-time {
      z-index: 1;
    }

    .remaining-time {
      opacity: 75%;
    }

    .remaining-icon {
      margin-left: $andes-spacing-16;
      margin-right: $andes-spacing-8;
      & > img {
        width: 14px;
        height: 15px;
      }
    }
  }

  &__remaining-info--gradient {
    &::after {
      background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 50%);
      content: ' ';
      height: 35px;
      left: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
