.dynamic-access-card-item {
  position: relative;
  &__title {
    line-height: 20px;
    padding: $andes-spacing-16 $andes-spacing-12 $andes-spacing-16
      $andes-spacing-16;
    margin: 0;
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    color: $andes-gray-900;
    max-width: 183px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  &__item-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__item-title {
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    font-family: $font-family-primary;
    color: $andes-text-color-primary;
    margin-bottom: $andes-spacing-8;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__item-description {
    margin: $andes-spacing-12 $andes-spacing-16 $andes-spacing-16;
    align-items: flex-start;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;

    .andes-money-amount-combo__main-container {
      flex-wrap: wrap;

      .andes-money-amount__cents {
        font-size: $font-size-12;
      }
    }
    .andes-money-amount--previous .andes-money-amount__cents {
      font-size: 8px;
    }
  }

  &__item-description--with-actions {
    margin: 12px 16px 8px;
  }

  &__container-shipping-free {
    color: $andes-green-500;
    display: flex;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 17px;
  }

  &__item-attributes {
    font-size: $font-size-12;
    color: $andes-gray-550;
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
  }

  &__container-tag {
    margin: $andes-spacing-4 0;
    max-height: $andes-spacing-24;
  }

  &__container-tag svg {
    margin: auto $andes-spacing-4 auto auto;
  }

  &__container-tag span {
    border-radius: $andes-spacing-4;
    color: $andes-white;
    display: flex;
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    height: fit-content;
    line-height: $line-height-s;
    padding: 3px 4px 2px;
    width: fit-content;
  }

  &__image {
    height: 105px;
    width: 105px;
    margin: 0 auto;

    &--LANDSCAPE {
      width: 100%;
      margin: 0;

      img {
        object-position: center;
      }
    }
  }

  &__subtext {
    font-weight: $font-weight-regular;
    color: $andes-text-color-secondary;
    margin-left: 3px;
  }

  .ui-homes-icon--full {
    fill: $andes-green-500;
    vertical-align: middle;
    width: 38px;
    height: 15px;
  }

  &__price {
    margin: 0;
    margin-bottom: 2px;
  }

  &__buy-now {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    line-height: 12px;
    margin: 16px;
    height: 24px;
    text-decoration: none;
  }

  &__buy-now--with-image {
    margin: 11px 16px 16px;
  }

  &__buy-now--with-image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__items-images {
    height: 192px;
    margin: 0 8px;

    img {
      height: 96px;
      width: 120px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }

    &__label {
      position: absolute;
      top: 40%;
      right: 40%;
      color: $andes-blue-500;
      font-weight: $font-weight-semibold;
      font-size: $font-size-18;
    }
  }

  &__items-images--2 {
    .dynamic-access-card-item__items-images-container:first-child {
      margin-bottom: 4px;
    }
  }

  &__items-images--3 {
    .dynamic-access-card-item__items-images-container:first-child {
      margin-bottom: 4px;
    }
    .dynamic-access-card-item__items-images-container:nth-child(2) {
      margin-right: 4px;
    }

    :nth-child(2),
    :nth-child(3) {
      height: 96px;
      width: calc(50% - 2px);
      display: inline-flex;

      img {
        width: 100%;
        object-position: center;
      }
    }
  }

  &__items-images--4 {
    .dynamic-access-card-item__items-images-container {
      height: 96px;
      width: calc(50% - 2px);
      display: inline-flex;
    }

    .dynamic-access-card-item__items-images-container:first-child {
      margin-bottom: 4px;
      margin-right: 4px;
    }

    .dynamic-access-card-item__items-images-container:nth-child(3) {
      margin-right: 4px;
    }

    img {
      width: 100%;
      object-position: center;
    }
  }

  &__items-images--last-image {
    height: 96px;
    width: calc(50% - 2px);
    display: inline-flex;
    position: relative;
    img {
      object-position: center;
    }
  }

  &__items-images--additional_items img {
    opacity: 0.5;
  }
}
