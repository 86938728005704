/* Convenient override of ui-library styles */
.ui-card {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
}

.clipped {
  position: absolute;
  z-index: -1;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: 0 -1px -1px 0;
  overflow: hidden;
  padding: 0;
  display: block;
}

// https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9#gistcomment-2389966
// @TODO find a better place for this
$spaceamounts: (
  0,
  2,
  4,
  5,
  8,
  10,
  12,
  14,
  15,
  16,
  20,
  24,
  25,
  30,
  32,
  35,
  40,
  45,
  48,
  64
);
$sides: (
  '': 'all',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

@each $space in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == '', '', -#{$value});

    .m#{$prefix}-#{$space} {
      margin#{$property}: #{$space}px;
    }

    .p#{$prefix}-#{$space} {
      padding#{$property}: #{$space}px;
    }
  }
}

.andes-carousel-free {
  scrollbar-width: none;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
}

.andes-carousel-free::-webkit-scrollbar {
  display: none;
}
