.national-insurance-info {
  &__link {
    height: 56px;
    display: block;
  }

  &__image {
    height: 41px;
    margin-top: 5px;
  }
}

[data-country='AR'] .nav-footer-access {
  margin-top: 74px;
}
