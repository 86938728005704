$slider-per-view-margin-rigth: 16px;
$slider-per-view-4: 4;
$slider-per-view-4-spacing: ($slider-per-view-margin-rigth * 3) / $slider-per-view-4;
$container-percentage-for-slider-4: calc(100% / $slider-per-view-4 - $slider-per-view-4-spacing);

.collections {
  $height: 445px;

  &-slide {
    height: $height;
    position: relative;
    overflow: visible;
    margin-bottom: 2px;
    margin-right: 1px;

    &_title-wrapper a {
      display: block;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    &-title {
      color: #333;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      margin: 16px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 20px;
      line-height: 25px;
    }

    a.collections-slide-item:hover {
      border-color: $blue;
    }

    &-background {
      overflow: hidden;
      text-align: center;
      height: 286px;
    }

    &-order-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-items {
      position: relative;
      z-index: 2;
      height: 76px;
      order: 1;
      padding: 0 20px;
      margin: 13px 0;
      overflow: hidden;
    }

    &-item {
      border: solid 2px #eee;
      border-radius: 4px;
      display: inline-block;
      height: 70px;
      overflow: hidden;
      width: #{$container-percentage-for-slider-4};
      margin-right: $slider-per-view-margin-rigth;
      margin-top: 3px;

      img {
        height: 70px;
        width: 70px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }

    &-background img {
      transition: transform 0.3s ease;
    }

    &_title-wrapper:hover {
     ~ .collections-slide-background img {
        transform: scale(1.05);
      }
    }
  }
}

