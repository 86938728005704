.card-grid {
  &-item {
    background-color: $andes-white;
    height: 100px;
    width: 270px;
    position: relative;
    display: flex;
    border: 1px solid $andes-gray-100;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 20px;
    
    &.andes-card--flat{
      box-shadow: none;
    }
    
    &.andes-card--padding-8 .card-grid-footer {
      padding-top: 0;
      align-self: center;
      border: 0;
      padding-left: 11px;
      padding-right: 11px;
      width: 170px;
      overflow: hidden;
    }
  }
  &-container-image {
    width: 90px;
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: $andes-spacing-16;
    padding-right:$andes-spacing-16;
    background: $andes-gray-040-solid;
    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  &-anchor {
    text-decoration: none;
    color: $andes-gray-900;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 5px;
    &:hover {
      color: $andes-blue-500;
    }
    &:focus-within { 
      @include add-focus-box-shadow;
    } 
    &::before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &-item__title {
    line-height: 22px;
    margin: auto;
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    overflow: hidden;
    hyphens: auto;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgb(167 167 167 / 40%);
  }

}
