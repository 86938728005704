$home-prefix: 'ui-homes';
$blue: #3483fa;
$font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', helvetica, roboto,
  arial, sans-serif;

// Carousel
$image-border-radius: 4px;
$sub-title: 20px;
$carousel-pagination-item-background: #c6c6c6;

// Links
$link-hover: #1259c3;

// Home
$light-gray: #ddd;
$dark-gray: #666;
$font-small: 14px;
$home-background: #ebebeb;
$section-separator: 42px;
$timeline: #dcdcdc;

// Item
$item-info-color: rgb(51 51 51);
$item-info-background: rgb(255 255 255 / 70%);
$item-shipping-free-color: #00a650;
$item-installment-free-color: #00a650;
$item-min-height: 314px;
$item-max-height: 370px;
$item-default-height: 350px;
$item-container-max-height: 415px;
$item-height-mobile: 230px;
$font-regular: 300;
$font-weight-semibold: 600;
$font-size-mid: 20px;
$item-shadow: 32px;
$item-width: 224px;

// Categories
$border-col-categories: #d7d7d7;
$item-margin: 36px;
$subtitle-color: #333;

// Navigation
$user-background: #f0f0f0;
$font-big: 28px;
$font-your-history: 26px;
$font-tiny: 10px;
$font-title: 16px;
$title-color: #1ac2b0;
$subtitle-history-color: #999;
$my-account-color: #3483fa;
$navigation-background: $home-background;

// Transitions
$transition-duration: 0.1s;
$transition-timing-function: ease-out;
$transition-visibility-delay: 0.1s;

// Loyalty
$partner-name-color: rgb(255 255 255 / 60%);
$partner-kicker-image: #bfbfbf;
$main-slider-background: #fff159;
