@use 'sass:math';
@import './site-shopping-info';

.site-shopping-info {
  .container {
    max-width: 1200px;
  }

  .info-slide {
    float: left;
    width: math.div(100, 3) * 1%;
  }

  .info-slide:nth-child(2) {
    &::before,
    &::after {
      background-color: #e2e2e2;
      content: '';
      display: block;
      margin-top: math.div(-65, 2) * 1px;
      height: 65px;
      position: absolute;
      top: 50%;
      width: 1px;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  a:hover {
    color: #1259c3;
  }

  @media screen and (width <= 1280px) {
    .info-slide {
      padding: 35px 24px 20px;

      h1 {
        line-height: 1.2;
      }
    }
  }
}
