section.regret {
  margin: 0;
  padding: 0;
  background-color: $andes-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.regret {
  color: $andes-gray-550-solid;
  font-weight: $font-weight-semibold;
  font-size: $font-size-16;
  text-align: center;
  background-color: $andes-white;

  &__box {
    padding: 16px;
    margin: 0 20px;
    border: 1px solid $andes-gray-100;
    border-radius: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    padding: 0;
    margin: 0;
    line-height: 1.3;

    /* Markup is dynamic so no classname selector is available */
    a {
      color: $andes-blue-500;
      font-weight: $font-weight-regular;
      font-size: $font-size-14;
      text-decoration: none;
      line-height: 1.3;
    }
  }

  &__container {
    display: flex;
    justify-content: space-evenly;
    max-width: 1200px;
    flex-grow: 1;
    align-items: stretch;
    padding: 52px 12px 12px;
  }
}
