.loyalty-buylevel6 {
  &__wrapper {
    width: 100%;
    background-color: $andes-white;
    box-shadow: 0 1px 1px 0 #0000001a;
    border-radius: $border-radius-6;
    overflow: hidden;
    max-width: 1180px;
  }

  &__header {
    padding: $andes-spacing-20;
    color: $andes-white;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(
      var(--deg_loyalty),
      var(--first_color_loyalty) var(--percentage_loyalty),
      var(--second_color_loyalty) 100%
    );
    align-items: center;

    &__title {
      font-size: $font-size-20;
      line-height: 25px;
      font-weight: $font-weight-semibold;
      display: flex;
      align-items: center;

      &-text {
        margin-right: 4px;
        color: var(--title_color, $andes-white);
      }

      &-img {
        height: 24px;
        margin-right: 10px;
      }

      &-text {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      &-price {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 4px;

        &--strike {
          color: $andes-white;

          .andes-money-amount__fraction::after,
          .andes-money-amount__currency-symbol::after {
            border-bottom: 1px solid $andes-white;
          }
        }

        &--value {
          .andes-money-amount__suffix {
            font-size: $font-size-20;
            color: $andes-white;
            margin: 0;
          }
        }

        .andes-money-amount__currency-symbol {
          padding: 0;
        }
      }
    }
  }

  &__body {
    padding: $andes-spacing-20;

    &-title {
      font-size: $font-size-18;
      line-height: 22px;
      display: block;
      font-weight: $font-weight-semibold;
    }

    &-benefits {
      display: flex;
      margin-top: $andes-spacing-20;
      justify-content: space-between;
      gap: $andes-spacing-20;
    }

    &-benefit {
      display: flex;
      flex: 1;
      align-items: center;
      max-width: 415px;
      width: 100%;

      &-logo {
        width: $andes-spacing-56;
        height: $andes-spacing-56;
        min-width: $andes-spacing-56;
        min-height: $andes-spacing-56;
        border-radius: $border-radius-6;
        border: solid 1px $andes-gray-070;
        overflow: hidden;

        &-img {
          width: 100%;
          height: auto;
        }
      }

      &-text {
        width: auto;
        margin: 0 $andes-spacing-16;
        font-size: $font-size-14;
        line-height: 18px;
      }
    }
  }

  &__action {
    &-title {
      font-size: $font-size-16;
    }

    &-button {
      background-color:  var(--button_background_color, #ffffff33) !important;
      color: var(--button_text_color, $andes-white);

      .andes-button__content {
        color: var(--button_text_color, $andes-white);
      }
    }
  }
}
