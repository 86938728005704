// Snapped
@import '~@recommendations-fe/carousel-snapped/src/styles/recommendations-fe-carousel-snapped.desktop';

.ui-recommendations-grid-section {

  @import './item';

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .row.container {
    padding: $andes-spacing-40 $andes-spacing-8;
  }

  @media (width <= 1263px) {
    .andes-carousel-snapped__control.andes-carousel-snapped__control--previous {
      left: -8px;
    }

    .andes-carousel-snapped__control.andes-carousel-snapped__control--next {
      right: -8px;
    }
  }
}
