// Globals
@import '../../../styles/mixins';
@import '../../../styles/variables';
@import './home';
@import '../../../styles/fonts';

// Shared
@import '../../commons/section/desktop';
@import '../../commons/squared-item/desktop';

// Andes
@import '~@andes/modal/base';
@import '~@andes/modal/lib/styles/large/index';
@import '~@andes/modal/lib/styles/large/actions';

@import '~@andes/snackbar/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/money-amount/lib/styles/combo';

// Carousels
@import './carousel/carousel.desktop';
@import '../../commons/dynamic-access/styles/desktop';
@import '../../commons/repurchase-widget/styles/desktop';
@import './categories/desktop';
@import './navigation/desktop';

// Recommendations
@import '../../commons/recommendations-snapped/desktop';
@import '../../commons/recommendations-grid/desktop';
@import '../../commons/recommendations-dual/desktop';

// Components
@import './loyalty-buylevel6/desktop/loyalty-buylevel6.desktop';
@import './advertising-adn/desktop';
@import './advertising-adn-video-banner/desktop';
@import './discovery/desktop';
@import './collections-v2/desktop';
@import './site-shopping-info/desktop';
@import '../../commons/regret/style/desktop';
@import '../../commons/row-regulation/index';
@import '../../commons/complaints-book/style/desktop';
@import '../../commons/help-email/style/desktop';
@import '../../commons/financial-user-info/style/desktop';
@import '../../commons/national-insurance-info/style/desktop';
@import './loyalty-interstitial/loyalty-interstitial.desktop';
@import './payment-data/desktop';
@import './thb-double/thb-double';

.home {
  overflow: hidden;
  position: relative;

  .ui-recommendations-carousel-snapped .andes-carousel-snapped--scroll-hidden {
    margin: 0;
    padding: 0;
    margin-top: 12px;
  }
}
.modal {
  width: 100%;
  height: 100%;

  .andes-modal__header {
    padding: $andes-spacing-64 $andes-spacing-64 $andes-spacing-32;
  }

  .andes-modal__content {
    padding: $andes-spacing-8 $andes-spacing-64 $andes-spacing-64;
  }
}

.onboarding-cp-button {
  height: auto;
}
