.ui-recommendations-grid-item {
  display: block;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 4px;
  width: 183px;
  height: 183px;

  &:first-child {
    margin-bottom: 12px;
  }

  &:hover {
    @include box-hover-shadow;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
}
