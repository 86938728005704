.andes-carousel-snapped__exhibitor-wrapper.andes-carousel-snapped__hero--desktop {
  position: absolute;
  width: 100vw;
  top: 0;

  .andes-carousel-snapped__control:focus-visible {
    opacity: 1;
  }

  .gradient {
    width: 100%;
    height: 30%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, $home-background 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
  }

  .andes-carousel-snapped__control {
    top: 40%;
    width: 60px;

    @media screen and (width > 1920px) {
      width: 65px;
    }
  }

  .andes-carousel-snapped__pagination--position-inner {
    bottom: calc(30% + 12px);
  }

  .andes-carousel-snapped__slide {
    justify-content: center;

    a img {
      height: auto;
      min-height: 400px;
      object-fit: cover;
  
      @media (width >= 1600px) {
        height: 500px;
        object-fit: none;
        max-width: 1920px;
      }
    }
  }
  
  .andes-carousel-snapped__control:focus-within {
    @include add-focus-box-shadow;
  }

  .andes-carousel-snapped a:focus-visible {
    border-top: 1px solid $andes-blue-600;
  }
}
