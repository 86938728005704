@import './section';

section {
  > .container {
    max-width: 1180px;
    margin: 42px auto;

    @media (width < 1180px) {
      margin: 42px 24px;
    }

    @media (width <= 769px) {
      margin: 0 20px;
      padding: 30px 0;
    }
  }

  .section-header {
    margin-bottom: $andes-spacing-20;

    h2 {
      font-size: $font-size-20;
      font-weight: $font-weight-semibold;
      margin: 0;
      color: $andes-text-color-primary;
    }

    a,
    .ui-link {
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;

      &:hover {
        color: $link-hover;
      }
    }
  }
}

.home {
  .row {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;

    &.center {
      justify-content: center;
    }

    h3 {
      margin-bottom: $section-separator;
      margin-top: $section-separator;
    }

    .title {
      color: #9c9c9c;
      font-size: 26px;
      font-weight: 200;
      margin-left: 6px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
