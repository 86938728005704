.site-shopping-info {
  overflow: hidden;
  background-color: #fff;
  margin-top: 20px;

  .info-slide {
    box-sizing: border-box;
    padding: 35px 45px 20px;
    text-align: center;
    position: relative;
    margin: 0 auto;

    > .img-container {
      height: 55px;

      img {
        vertical-align: middle;
        width: auto;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      color: #4b4b4b;
      margin: 16px 0 0;
    }

    p {
      color: $andes-gray-550-solid;
      font-size: 15px;
      margin: 4px 0 10px;
      line-height: 1.2;
    }

    a {
      color: #3483fa;
      font-size: 14px;
      text-decoration: none;
    }
  }
}
