// Colors

.font-color--GREEN {
  color: $andes-success-green;
}

.font-color--GRAY {
  color: $andes-gray-550;
}

.font-color--LIGHT_GRAY {
  color: $andes-gray-070;
}

.font-color--BLACK {
  color: $andes-gray-900;
}

.font-color--RED {
  color: $andes-error-red;
}

.font-color--ORANGE {
  color: $andes-warning-orange;
}

.font-color--BLUE {
  color: $andes-blue-500;
}

.font-color--WHITE {
  color: $andes-white;
}

// Font sizes
$font-size-10: 10px;

.font-size--XXXSMALL {
  font-size: $font-size-10;
}

.font-size--XXSMALL {
  font-size: $font-size-12;
}

.font-size--XSMALL {
  font-size: $font-size-14;
}

.font-size--SMALL {
  font-size: $font-size-16;
}

.font-size--MEDIUM {
  font-size: $font-size-18;
}

.font-size--LARGE {
  font-size: $font-size-20;
}

.font-size--XLARGE {
  font-size: $font-size-24;
}

.font-size--XXLARGE {
  font-size: $font-size-28;
}

// Font weights

.font-family--LIGHT {
  font-weight: $font-weight-light;
}

.font-family--REGULAR {
  font-weight: $font-weight-regular;
}

.font-family--SEMIBOLD {
  font-weight: $font-weight-semibold;
}

.font-family--BOLD {
  font-weight: $font-weight-bold;
}

.background-color--BLUE {
  background-color: #3483fa;
}

.background-color--GREEN {
  background-color: $andes-green-500;
}

.background-color--WHITE {
  background-color: #fff;
}

.background-color--ANDES-SECONDARY-E-700 {
  background-color: $andes-secondary-e-700;
}

.background-color--TRANSPARENT {
  background: $andes-blue-150;
}

.text-alignment--center {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
