.ui-card-seed__cart {
  border: none;
  cursor: pointer;
  background-color: $andes-white;
  border-radius: $border-radius-4;
  min-height: var(--max-card-height, auto);
  font-weight: $font-weight-regular;
  height: 100%;
  margin: 0;
  width: 100%;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  position: relative;

  &:hover {
    box-shadow: 0 7px 11px 0 rgb(0 0 0 / 10%), 0 2px 4px 0 rgb(0 0 0 / 10%);
    text-decoration: none;
  }

  .ui-card-seed__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dadada;
    height: 220px;
    padding: 20px;

    .quantity-1,
    .quantity-2,
    .quantity-3,
    .quantity-4,
    .quantity-more {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0 -1px 30px 0 rgb(0 0 0 / 20%),
        0 2px 3px 0 rgb(0 0 0 / 10%);
      border-radius: $border-radius-4;

      .col-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          box-shadow: none;
          border-radius: 0;
          object-fit: cover;
        }
      }
    }

    .quantity-2,
    .quantity-3,
    .quantity-4,
    .quantity-more {
      .col-image:first-child {
        border-right: 1px solid #dedede;
      }
    }

    .quantity-3 {
      .col-image:last-child {
        img {
          height: 50%;

          &:first-child {
            border-bottom: 1px solid #dedede;
          }
        }
      }
    }

    .quantity-4,
    .quantity-more {
      .col-image {
        img {
          height: 50%;

          &:first-child {
            border-bottom: 1px solid #dedede;
          }
        }
      }
    }

    .quantity-more {
      .col-image:last-child {
        position: relative;

        .quantity {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(255 255 255 / 80%);
          border-radius: $border-radius-4;
          width: 100%;
          height: 50%;
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: $font-size-32;
          font-weight: $font-weight-semibold;
          text-align: center;
          color: $blue;
        }
      }
    }
  }

  .ui-card-seed__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ui-card-seed__title {
    color: #333;
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    line-height: 0.92;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
  }

  .ui-card-seed__link {
    color: #333;
    text-decoration: none;

    &:focus,
    &:active,
    &:hover {
      color: #333;
      text-decoration: none;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .ui-card-seed__subtitle {
    color: #666;
    font-size: $font-size-14;
    font-weight: $font-weight-light;
    line-height: 1.3;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
