section.regulation-row {
  background-color: $andes-white;

  div.container {
    margin: 0 auto;

    @media (width < 1180px) {
      margin: 0 24px;
    }
    
    .container-regulation-row {
      display: flex;
      justify-content: end;
      gap: 10px;
    }
  }
}
