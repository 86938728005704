.advertisement {
  margin: 0 auto;

  &.hidden {
    display: none;
  }

  .frame-container {
    border-radius: 4px;
    overflow: hidden;
  }
}
