.dynamic-access-card-grid-media {
  position: relative;
  display: flex;
  flex-direction: column;

  &__title {
    line-height: 20px;
    padding: $andes-spacing-16;
    margin: 0;
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    color: $andes-gray-900;
    max-width: 183px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  &__item-description {
    margin: $andes-spacing-12 $andes-spacing-16 0 $andes-spacing-16;
    height: 67px;
    font-size: $font-size-14;
    line-height: $font-size-18;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__action {
    border-radius: $andes-spacing-4;
    margin: $andes-spacing-16;
  }

  &__pictures {
    margin: 0 $andes-spacing-16;

    img {
      height: 96px;
      width: 120px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
  }

  &__pictures--1 {
    img {
      height: 105px;
      width: 120px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__pictures--2 {
    :nth-child(1),
    :nth-child(2) {
      height: 100px;
      width: calc(50% - 2px);
      display: inline-flex;

      img {
        width: 100%;
        object-position: center;
      }
    }
  }
}
