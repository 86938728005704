.complaints-book {
  margin: 0;
  padding: 0;
  background-color: $andes-white;
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    width: 102px;
    height: 64px;
  }
}
